import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TeamMember from "../components/teamMember";

// import Dots from '../images/team/dots.svg'
import EdwinHo from "../images/team/Edwin-Ho.png";
import MasonPeck from "../images/team/Mason-Peck.png";
import Betty from "../images/team/Betty.png";
import Joyce from "../images/team/Joyce.png";
import Patrick from "../images/team/Patrick.png";
import DanB from "../images/team/DanB.png";
import Michael from "../images/team/Michael.png";
import Matt from "../images/team/matt.png";
import Brian from "../images/team/Brian.png";
import Puneet from "../images/team/Puneet.png";
import AnkurBorah from "../images/team/Ankur-Borah.png";
import Ferdi from "../images/team/Ferdi.png";
import Dagz from "../images/team/Dagz.png";
import Dhanujaya from "../images/team/dhanujaya.png";
import Franklin from "../images/team/Franklin.png";
import Peter from "../images/team/Peter.png";
import Nitish from "../images/team/nitish.png";
import Suvrat from "../images/team/Suvrat.png";
import Brandon from "../images/team/Brandon-pic.png";
import Edmund from "../images/team/Edmund-pic.png";

function Team() {
  return (
    <Layout>
      <ScrollUpButton ContainerClassName="custom-scroll-button" />
      <Seo title="Team" />
      <Container className="py-5">
        <Row className="justify-content-center text-center mb-5">
          <Col lg={12} className="mb-3">
            <div className="section-title">
              <div>Meet The Team</div>
              <h3>Meet The Team</h3>
            </div>
          </Col>

          <Col lg={8} md={10} className="mb-md-5">
            <p>
              Meet the Team behind HedgePay. 15 people in 5 departments are dedicated to bringing to success to all
              members of the HPAY family. Development, operations, communication, sales and partnership departments are
              all working passionately in all our departments to achieve this goal.
            </p>
          </Col>
        </Row>

        <div className="team-group">
          <Row className="justify-content-center">
            <TeamMember
              pic={MasonPeck}
              name="Mason Peck"
              designation="Founder & CEO"
              telegram="https://t.me/HedgePayCEO"
              twitter="https://twitter.com/HedgePayCEO"
              linkedin="https://www.linkedin.com/in/hedgepayceo/"
              github=""
              gitlab=""
              content="Mason has been an innovator in business ownership for the past 10 years. He has directed several businesses to financial success, working side-by-side with employees, and focusing on active innovation to improve the overall adoption if new technologies. Mason hold a Master of science degree and has personally owned 3 businesses. Mason has the drive, the vision, and the real-life experience to bring about the widespread adoption of HedgePay's international business strategies. Let's welcome Mason to HedgePay!"
            />
            <TeamMember
              pic={DanB}
              name="Dan B"
              designation="Co Founder & Dev"
              telegram="https://t.me/TheGreenVixen"
              twitter="https://twitter.com/0xVersion"
              linkedin=""
              github="https://github.com/hedgepaybsc/"
              gitlab=""
              content="Software engineer and blockchain consultant with over 7 years of experience developing fintech and blockchain applications. Has a bachelor's degree in computer science with a specialization in distributed computing, deep learning and blockchain development. Architected and helped implement the HedgeFi protocol and adjacent HedgePay services."
            />
            <TeamMember
              pic={EdwinHo}
              name="Edwin Ho"
              designation="Co Founder & Director"
              telegram="https://t.me/HedgePay_Ed"
              twitter="https://twitter.com/edwinho_kc"
              linkedin="https://www.linkedin.com/in/edwinhokeecheong"
              github=""
              gitlab=""
              content="For the last 17 years, Edwin has spent his time collaborating with all hierarchies of the digital industry. From startups to multinational corporations, Edwin has constructed innovative marketing strategies that transcend geographical barriers. His outreach has established prosperous and immutable relationships between clients and consumers. Always looking forward, Edwin sees a new age of marketing coming, that will interlace cryptocurrencies with purchases in our daily lives. HedgePay welcomes Edwin to become one of the pioneers that will establish this new economy."
            />
            <TeamMember
              pic={Betty}
              name="Betty"
              designation="Hedge Fund Manager"
              telegram="https://t.me/HedgePayManager"
              twitter=""
              linkedin=""
              github=""
              gitlab=""
              content="Betty has been an entrepreneur since 2015 when she first left her 48 hr a week job to strike out on her own. From insurance adjusting to quality control she has spent the past years cultivating experience that will help lead us in the new century of cryptocurrency innovation. Let's all welcome her to HedgePay."
            />
            <TeamMember
              pic={Joyce}
              name="Joyce Lai"
              designation="Account Manager"
              telegram="https://t.me/I_am_LSS"
              twitter="https://twitter.com/SiewShanLai1"
              linkedin="https://www.linkedin.com/in/joycelai1210"
              github=""
              gitlab=""
              content="Joyce Lai graduated with a Bachelor’s in finance studies 3 months ago. She has been handling the accounts and collecting receipts from everyone to submit for income tax in the month of December 2022."
            />
            <TeamMember
              pic={Patrick}
              name="Patrick"
              designation="Listing Manager"
              telegram="https://t.me/patl1515"
              twitter=""
              linkedin=""
              github=""
              gitlab=""
              content="Patrick, with his 12 years of experience in advertising and marketing, has worked within multiple industries bringing a fresh new approach to whomever he has partnered with.  He holds degrees in Graphic Design, Business Marketing and technical certifications in a multitude of design software.  Patrick also has years of direct sales in the New York metro area specializing in small and large unit sales.  With knowledge of working with small start-ups and micro business launches, he hopes to bring a new look and apprach to Hedgepay."
            />
            <TeamMember
              pic={Brian}
              name="Brian"
              designation="Frontend Dev"
              telegram="https://t.me/brianchzy"
              twitter=""
              linkedin="https://www.linkedin.com/in/brian-chang-a0788359"
              github=""
              gitlab=""
              content="Brian has built and advised hundreds of startups and turned them into established SMEs. Brian is a Marketing Degree graduate from Leeds Metropolitan in the United Kingdom and a self-taught developer. Brian combines the two fields to provide a common understanding between developer and marketing, develop a practical solution and enhance user experiences."
            />
            <TeamMember
              pic={Puneet}
              name="Puneet"
              designation="Frontend Dev"
              telegram=""
              twitter=""
              linkedin=""
              github="https://github.com/hedgepaybsc/"
              gitlab=""
              content="HedgePay's development team is currently under a Non-Disclosure Agreement (NDA), and we have chosen not to disclose the names of those involved due to security purposes. HedgePay always places security as a core tenant and we keep our developments in-house. This works to protect our investors and developers from compromise."
            />
            <TeamMember
              pic={Nitish}
              name="Nitish"
              designation="Backend Dev"
              telegram="https://t.me/NitishBeejawat"
              twitter="https://twitter.com/NitishBeejawat?t=X1GfTOOeVXUwAmU3AAKUag&s=09"
              linkedin="https://www.linkedin.com/in/nitish-beejawat-89a928108"
              github=""
              gitlab=""
              content="Nitish is a Full Stack Blockchain Developer. He has around 3 years of experience in blockchain development. He has worked with Bitcoin, Ripple, Polygon, Ethereum, BSC, Tron, Avalanche etc. Developing cryptocurrencies, ICO, IPO, erc20 tokens, stable coins, De-Fi apps, decentralized exchange, NFT platforms, liquidity pools, crypto lending websites and much more. He has a strong hold in solidity, and web3 technologies. He keeps himself up to date with the latest trends in the De-Fi space. Proficient at performing as well as working in a group or independently at a fast-paced setting. He has a deep understanding of distributed ledger, blockchain safety, blockchain design principles, and various blockchain protocols and environments."
            />
            <TeamMember
              pic={AnkurBorah}
              name="Ankur Borah"
              designation="Operations"
              telegram="https://t.me/SirMarshal"
              twitter="https://twitter.com/ankurborahbnb"
              linkedin="https://www.linkedin.com/in/ankurborah13/"
              github=""
              gitlab=""
              content="Ankur is from India and has many years of experience in Blockchain Domain. He has worked with multiple start-ups, building a community from scratch, growing it organically, and exponentially. Worked with DeFi such as Titano Forks and auto-stake rebase projects, NFT Gaming Projects, Memecoins and Metaverse projects. Have deep understanding in IDO launchpads, INO, Staking Vaults, NFT Staking and Auto-Staking Rebase. He is currently working in the Operations department with HedgePay for the past 3 months."
            />
            <TeamMember
              pic={Ferdi}
              name="Ferdi"
              designation="Operations"
              telegram="https://t.me/kingferdii"
              twitter="https://twitter.com/ferdi2107?t=M1Dry_o5xHdAvKqgtUS9Uw&s=09"
              linkedin="https://www.linkedin.com/in/ferdi-nan-b09a33222"
              github=""
              gitlab=""
              content="Ferdi comes from Indonesia and has graduated with a Bachelor's degree in Informatics Engineering from the Padang State University (UNP) West Sumatra."
            />
            <TeamMember
              pic={Dagz}
              name="Dagz"
              designation="Operations"
              telegram="https://t.me/DagzyBunny"
              twitter="https://twitter.com/DagzCryptoLife"
              linkedin="https://www.linkedin.com/in/christianlaput/"
              github=""
              gitlab=""
              content="I've been a Community Manager & Chat Moderator for a year and now I work Operations at HedgePay for 4 months. I love how Ed and the team manages their work and I can see our future full of surprises here. I'm also here to ensure the growth of the HedgePay Philippines community as well."
            />
            <TeamMember
              pic={Peter}
              name="Peter"
              designation="Investment"
              telegram="https://t.me/Irony_128"
              twitter="https://twitter.com/Peter94448348"
              linkedin=""
              github=""
              gitlab=""
              content="Peter is German, but lives in the French-speaking part of Switzerland. He is no professional but passionate about blockchain and crypto. He dived into crypto in 2017, but sold his Bitcoin way too early. He also works in the medical services and his hobbies include investing and trading."
            />
            <TeamMember
              pic={Matt}
              name="Matt"
              designation="Communications"
              telegram="https://t.me/Matt2"
              twitter="https://twitter.com/mattstewart12"
              linkedin="www.linkedin.com/in/matt-stewart-8896a4150"
              github=""
              gitlab=""
              content="My name is Matt , I have been in crypto for 3+ years. Play Crown Green Bowls in past time and love football ."
            />
            <TeamMember
              pic={Franklin}
              name="Franklin"
              designation="Communications"
              telegram="https://t.me/Franklin5522"
              twitter="https://twitter.com/NduOxford"
              linkedin="https://www.linkedin.com/in/ndu-boy-franklin-30610923a"
              github=""
              gitlab=""
              content="I am Franklin, a medical student studying Human Physiology in NICOSIA DISTRICT (Lefkoşa), Cyprus. I am also a crypto lover, got interested a few years ago, have been doing research, and learning more about Crypto. I work mostly on BSC and ETH projects, providing services like public relations and communication services and it's really an honor to be working with the HedgePay team."
            />
            <TeamMember
              pic={Michael}
              name="Michael"
              designation="Operations"
              telegram="https://t.me/Michaeloneday"
              twitter="https://twitter.com/Michael_1day"
              linkedin="https://www.linkedin.com/in/michael-oliveros-555575235/"
              github=""
              gitlab=""
              content="Michael has been very active and committed to HedgePay. He works as a counselor during his day job and day & night at HedgePay. Let's all welcome Michael again for his hardwork in the HedgePay family!"
            />
            <TeamMember
              pic={Suvrat}
              name="Suvrat"
              designation="UI UX Designer"
              telegram="https://t.me/Suvratkapoor"
              twitter=""
              linkedin="https://www.linkedin.com/in/suvrat-kapoor-75533424a/"
              github=""
              gitlab=""
              content="Suvrat completed his Bachelor’s of Engineering in Civil. He worked as a Site Engineer for 2 years for a corporate company. Alongside he followed his passion in designing and  did certification in Figma and UI/UX from Udemy and Coursera . He is now working as an UI and graphic designer Intern for Websetters. "
            />
            <TeamMember
              pic={Brandon}
              name="Brandon"
              designation="Senior BD"
              telegram="https://t.me/brandon_dev"
              twitter="https://twitter.com/BitosAlpha_"
              linkedin="https://www.linkedin.com/in/brandonyuenkt"
              github=""
              gitlab=""
              content="Deeply passionate about Web 3.0 and fully dedicated to exploring the latest advancements and emerging technologies"
            />
            <TeamMember
              pic={Edmund}
              name="Edmund"
              designation="Senior BD"
              telegram="https://t.me/ImWongg_Vegeta"
              twitter="https://twitter.com/ImWonggg"
              linkedin="https://www.linkedin.com/in/edmund-wong-593b47210/"
              github=""
              gitlab=""
              content="Outgoing and Enthusiastic in Crypto Space, I believe that building a strong network and exchanging ideas is crucial to success in this field."
            />
          </Row>
        </div>
      </Container>
    </Layout>
  );
}

export default Team;